<template>
    <router-view />
</template>

<script>
import "@/assets/scss/main.scss"

export default {
  title: "Six Clovers"
}
</script>

